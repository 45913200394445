.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  cursor: pointer;
}
