.subImgsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  margin: 5px 0px 0px 0px;
}

.subImgContainer {
  position: relative;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  height: 70px;
}

.subImage {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.subImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.subImgContainer input {
  display: none;
}

.removeButton {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;
}

.removeButton svg {
  font-size: 12px;
  color: lightgray;
}

@media only screen and (max-width: 420px) {
  .subImgContainer {
    height: 60px;
  }
}

@media only screen and (max-width: 350px) {
  .subImgContainer {
    height: 50px;
  }
}

@media only screen and (max-width: 310px) {
  .subImgContainer {
    height: 40px;
  }
}
