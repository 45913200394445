.menuButton {
  border-radius: 20px;
  font-size: 30px;
  margin-left: 13.5px;
  color: #fff;
  cursor: pointer;
  display: none;
}

@media only screen and (max-width: 500px) {
  .menuButton {
    display: block;
  }
}
