.label {
  display: block;
  width: 100%;
  margin: 15px 0px 5px 0px;
  font-size: 14px;
}
.input {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  outline: none;
}

.textarea {
  resize: none;
  height: 150px;
  width: 100%;
  outline: none;
  box-sizing: border-box;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  gap: 10px;
}

.checkBoxContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  height: 40px;
  margin: 10px 0px 10px 0px;
}

.checkBoxContainer label {
  font-size: 14px;
  color: gray;
}

.checkBoxContainer input {
  transform: scale(2);
  outline: none;
}

.error {
  margin: 0px 0px 5px 0px;
  color: red;
  font-size: 12px;
}
