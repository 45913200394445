body {
  margin: 0px;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

body input {
  font-family: "Roboto", sans-serif;
}

body textarea {
  font-family: "Roboto", sans-serif;
}

body pre {
  font-family: "Roboto", sans-serif;
}

.active {
  background-color: rgb(12, 12, 12) !important;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
