.main {
  flex: 1;
  background-color: black;
  padding: 50px 10px 200px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin: auto auto;
  max-width: 200px;
  height: 30px;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.Profile {
  max-width: 840px;
  width: 100%;
  background-color: rgb(61, 61, 61);
  border-radius: 5px;
  padding: 20px 10px 24px 10px;
}

.Profile p {
  margin: 0px;
  color: #fff;
}

.nameAndRoleContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0px 30px 0px;
}

.name {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.contactInformation {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonContainer {
  margin-top: 30px !important;
  margin: 0 auto;
  max-width: 200px;
}

.buttonContainer button {
  display: grid;
  align-items: center;
  width: 100%;
  color: #fff;
  outline: none;
  height: 30px;
  border: none;
  background-color: rgb(211, 164, 36);
  border-radius: 5px;
  cursor: pointer;
}
