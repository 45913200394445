.form {
  max-width: 300px;
  width: 100%;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: rgb(61, 61, 61);
  border-radius: 5px;
  padding: 20px;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  background-color: lightgray;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
}

.imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgContainer svg {
  font-size: 50px;
  color: #ffff;
}

.galleryImgInput {
  display: none;
}

.messageContainer {
  height: 35px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  overflow: hidden;
}

.form button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100%;
  width: 100%;
  margin: 20px 0px 0px 0px !important;
  margin: 0 auto;
  background-color: rgb(211, 164, 36);
  border: none;
  border-radius: 5px;
  color: #ffff;
  outline: none;
  cursor: pointer;
}
