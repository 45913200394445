.supportButton {
  position: relative;
  width: 90px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.supportEmailAmount {
  position: absolute;
  top: -25px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 25px;
  width: 25px;
  background-color: yellow;
  color: black;
  z-index: 1;
}

.active {
  background-color: #083f72;
}
