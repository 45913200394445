.cart {
  position: fixed;
  top: 0;
  right: -100%;
  width: 250px;
  height: 100vh;
  background-color: #f1f1f1;
  z-index: 9999;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  transition: right 0.4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container::-webkit-scrollbar {
  display: none;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0px 0px 10px 0px;
  background-color: #fff;
}

.iconAndHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cartIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
}

.h2 {
  font-weight: lighter;
  margin: 0px;
  font-size: 20px;
}

.closeCartButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  border-left: 1px solid lightgray;
  color: rgb(170, 169, 169);
  cursor: pointer;
}

.emptyCartText {
  color: gray;
  text-align: center;
  margin: 30px 0px 30px 0px;
}

.active {
  right: 0;
}

@media only screen and (max-width: 500px) {
  .cart {
    width: 100%;
  }
}
