.productInfoContainer {
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgb(61, 61, 61);
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.productInfoContainer h1 {
  margin: 0px 0px 20px 0px;
  font-size: 25px;
}

.PriceAndStockContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.priceContainer {
  display: flex;
  gap: 10px;
  font-weight: bold;
}

.priceContainer p {
  margin: 0px;
}

.discountPrice {
  text-decoration: line-through;
  color: red;
}

.stockStatus {
  color: rgb(173, 248, 173);
  margin: 0px;
  font-weight: bold;
}

#outOfStock {
  color: #fa8b8b;
}

.description {
  color: #ffffff;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: lighter;
}

.productInfoContainer h2 {
  margin: 50px 0px 10px 0px;
  font-weight: lighter;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.designText {
  margin: 0px;
  color: #ffffff;
  font-weight: lighter;
}
.designText2 {
  margin: 0px;
  color: #ffffff;
  font-weight: lighter;
}

@media only screen and (max-width: 800px) {
  .productInfoContainer {
    max-width: 500px;
    margin: 0 auto;
  }
}
