.aboutImageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  height: 390px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.aboutImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.editButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gray;
  background-color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.textContainer {
  max-width: 500px;
  margin-top: 40px !important;
  margin: 0 auto;
}

.textContainer pre {
  white-space: pre-wrap;
}
