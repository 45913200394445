.main {
  flex: 1;
  background-color: black;
  padding: 50px 10px 200px 10px;
}

.inbox {
  max-width: 1000px;
  background-color: #f1f1f1;
  margin: 0 auto;
  padding: 0px 0px 5px 0px;
  min-height: 500px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.topBar {
  background-color: #0c59a1;
}

.inbox h1 {
  color: #fff;
  font-weight: lighter;
  padding: 20px 0px 0px 20px;
  margin: 0px 0px 50px 0px;
}

.actionButtons {
  display: flex;
  color: #fff;
  width: 100%;
}

.unreadMessagesAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #fff;
  margin: 0px 0px 5px 0px;
  padding: 0px 10px 0px 10px;
}

.unreadMessagesAmount p {
  margin: 0px;
}

.filterAndDeleteContainer {
  display: flex;
  gap: 20px;
}
