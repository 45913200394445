.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-width: 840px;
  margin: 0 auto;
}

.card {
  position: relative;
  max-width: 200px;
  background-color: #ffff;
  border-radius: 10px;
  padding: 5px 5px 10px 5px;
  overflow: hidden;
}

.dummyImage {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-color: rgb(189, 189, 189);
  background: linear-gradient(to left, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.7) 50%, rgba(51, 51, 51, 0) 100%);
  background-size: 200% 100%;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.dummyContent {
  max-width: 100%;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin: 0 auto;
  height: 20px;
  background: linear-gradient(to left, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.7) 50%, rgba(51, 51, 51, 0) 100%);
  background-size: 200% 100%;
  animation: shine 2s linear infinite;
}

@media only screen and (max-width: 1000px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    max-width: 640px;
  }
}

@media only screen and (max-width: 700px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    max-width: 440px;
  }
}

@media only screen and (max-width: 500px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 10px 0px 10px;
    gap: 30px;
  }

  .card {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
