.main {
  padding: 10px 20px 200px 20px;
  margin: 0 auto;
  flex: 1;
  background-color: black;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main h1 {
  font-weight: lighter;
  color: #fff;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;
}

.products {
  display: flex;
  gap: 30px;
  max-width: 1000px;
  width: 100%;
  background-color: rgb(0, 0, 0);
  justify-content: space-between;
  margin: 0 auto;
}

.cardsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}

.totalPrice {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  height: 50px;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  margin-right: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media only screen and (max-width: 935px) {
  .products {
    flex-direction: column;
  }
}
