.featuredContainer {
  margin-top: 50px !important;
}

.h2 {
  font-weight: lighter;
  font-size: 20px;
  color: #fff;
  padding-left: 20px;
  max-width: 840px;
  margin-bottom: 20px !important;
  margin: 0 auto;
}

.carousel {
  padding-bottom: 40px;
  position: relative;
  max-width: 840px;
  margin: 0 auto;
}

.carousel button {
  z-index: 0;
}

.cardWrapper {
  margin-left: 10px;
}

.dots button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: none !important;
  background-color: lightgray;
}

.dots button:active {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: none !important;
  background-color: rgb(182, 16, 16) !important;
}

@media only screen and (max-width: 1024px) {
  .carousel,
  .featuredContainer h2 {
    max-width: 630px;
  }
}

@media only screen and (max-width: 660px) {
  .carousel,
  .featuredContainer h2 {
    max-width: 420px;
  }
}

@media only screen and (max-width: 460px) {
  .carousel {
    max-width: 200px;
  }

  .featuredContainer h2 {
    text-align: center;
    padding-left: 0px;
  }
}
