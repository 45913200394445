.main {
  background-color: black;
  padding: 50px 0px 200px 0px;
  flex: 1;
}

.h1 {
  max-width: 840px;
  margin-bottom: 25px !important;
  margin: 0 auto;
  color: #fff;
  font-weight: lighter;
}

.noCardsMessage {
  color: #afafaf;
  text-align: center;
  margin-top: 100px;
}

@media only screen and (max-width: 1000px) {
  .h1 {
    max-width: 640px;
  }
}

@media only screen and (max-width: 700px) {
  .h1 {
    max-width: 440px;
  }
}

@media only screen and (max-width: 500px) {
  .h1 {
    text-align: center;
  }
}
