.openFormButton {
  display: none;
  justify-content: center;
  align-items: center;
  background: rgb(61, 61, 61);
  color: #fff;
  border: none;
  height: 28px;
  width: 50px;
  cursor: pointer;
  font-size: 13px;
}

@media only screen and (max-width: 500px) {
  .active {
    display: flex;
  }
}
