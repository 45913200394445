.inboxButton {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: #fff;
  color: gray;
}

.inboxButton p {
  position: absolute;
  top: 0px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: yellow;
  height: 23px;
  width: 23px;
  border-radius: 20px;
  font-weight: bold;
  z-index: 10;
}
