.buttonContainer {
  position: relative;
  width: 100%;
}

.addToCartButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(211, 164, 36);
  width: 100%;
  height: 40px;
  margin-top: 50px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.tooltopContainer {
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  margin-bottom: 5px;
  margin: 0 auto;
}
