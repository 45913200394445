.warningContainer {
  position: relative;
  visibility: hidden;
}

.warningIcon {
  color: orange;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolTopContainer {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

#active {
  visibility: visible;
}
