.main {
  background-color: #fff;
  padding-bottom: 200px;
  flex: 1;
}

.contactInfoBackground {
  background-color: black;
  padding: 20px;
  height: 700px;
}

.h1 {
  text-align: center;
  font-size: 35px;
  margin-bottom: 50px;
}

.contactInfoContainer {
  display: flex;
  justify-content: space-between;
  max-width: 750px;
  margin: 0 auto;
}

.imgContainer {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
}

.role {
  font-size: 14px;
  margin: 0px 0px 40px 0px;
  text-align: center;
  color: #fff;
}

.phoneAndEmailContainer {
  display: grid;
  gap: 10px;
}

.phoneContainer,
.emailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number,
.email {
  font-size: 14px;
  font-style: italic;
  color: #fff;
  word-break: break-all;
}

.phoneIcon,
.emailIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: rgb(42, 104, 88);
  font-size: 25px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .contactInfoBackground {
    height: 1400px;
  }

  .contactInfoContainer {
    flex-direction: column;
    gap: 100px;
  }
}

@media only screen and (max-width: 300px) {
  .imgContainer {
    max-width: 150px;
    height: 150px;
  }
}
