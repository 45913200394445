.cards {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card {
  position: relative;
  display: flex;
  gap: 20px;
  height: 70px;
  margin: 0px 5px 0px 5px;
  background-color: #fff;
}

.imgContainer {
  max-width: 70px;
  height: 100%;
}

.imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cardBody {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  height: 100%;
}

.cardBody p {
  margin: 0px;
  font-size: 14px;
}

.amountContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.amountRed {
  color: red;
  font-weight: bold;
}
