.main {
  background-color: black;
  flex: 1;
  padding: 0px 0px 300px 0px;
}

.bannerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  margin: 0 auto;
  padding: 0px 10px 0px 10px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner p {
  text-align: center;
  margin: 0px;
  max-width: 200px;
  margin: 0 auto;
  color: #fff;
}

.h1Container {
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 400px;
}

.h1Container h1 {
  text-align: center;
  font-size: 30px;
  font-weight: lighter;
  color: #fff;
}

.editButton {
  position: absolute;
  top: -15px;
  right: -15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gray;
  background-color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.buttonContainer a {
  color: #fff;
  text-decoration: none;
}

.shopButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: #fff;
  margin: 0 auto;
  margin-top: 30px;
  background-color: rgb(1, 119, 173);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 150px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.active {
  display: block;
}
