.form {
  max-width: 300px;
  position: relative;
}

.inputContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 50px;
}

.form input {
  width: 100%;
  height: 20px;
  outline: none;
}

.form button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: rgb(61, 61, 61);
  color: #fff;
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.messageContainer {
  position: absolute;
  bottom: -53px;
  max-width: 300px;
  word-break: break-all;
}

.clearMessageButton {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: red;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  opacity: 0;
  animation: clearButton-appear 0.5s forwards;
}

@keyframes clearButton-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .form {
    max-width: 100%;
    display: none !important;
  }

  .active {
    display: block !important;
  }
}
