.header {
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logoContainer {
  max-width: 280px;
  margin: 0 auto;
}

.logoContainer img {
  width: 100%;
}

.navigationContainer {
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100px;
  bottom: 0;
  padding-top: 10px;
  z-index: 10;
}

@media only screen and (max-width: 500px) {
  .navigationContainer {
    display: none;
  }
}
