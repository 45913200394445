.galleryContainer {
  max-width: 500px;
  width: 100%;
  max-height: 400px;
  height: 100%;
  margin: 0 auto;
}

.mainImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.mainImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subImagesContainer {
  display: none;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin: 10px 0px 0px 0px;
}

.subImage {
  border-radius: 10px;
  overflow: hidden;
  height: 70px;
  width: 100%;
  transition: transform 0.3s ease;
}

.subImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.subImage:hover {
  transform: translate(1px, -1px);
}

.active {
  display: grid;
}

@media only screen and (max-width: 850px) {
  .mainImageContainer {
    height: 300px;
  }

  .subImage {
    height: 60px;
  }
}

@media only screen and (max-width: 800px) {
  .galleryContainer {
    max-height: 600px;
  }

  .mainImageContainer {
    height: 400px;
  }

  .subImage {
    height: 70px;
  }
}

@media only screen and (max-width: 400px) {
  .mainImageContainer {
    height: 300px;
  }

  .subImage {
    height: 60px;
  }
}
