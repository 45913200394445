.main {
  background-color: black;
  flex: 1;
  padding: 50px 0px 300px 0px;
}

.goBackButtonWrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.goBackButton {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  width: fit-content;
}

.goBackButton p {
  font-size: 14px;
}

.detailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  max-width: 1000px;
  margin: 0 auto;
}

@media only screen and (max-width: 800px) {
  .detailsContainer {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
