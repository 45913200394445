.form {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 500px;
  border-radius: 10px;
  margin-top: 30px !important;
  margin: 0 auto;
  box-sizing: border-box;
  height: 590px;
}

.form h2 {
  font-weight: lighter;
  height: 50px;
  margin: 50px 0px 0px 0px;
}

.messageContainer {
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
  margin: 50px 0px 0px 0px;
}

.form label {
  margin: 20px 0px 5px 0px;
}

.error {
  color: red;
  margin: 0px 0px 5px 0px;
  font-size: 14px;
}

.form input {
  outline: none;
  height: 20px;
}

.form textarea {
  outline: none;
  height: 150px;
  font-family: Arial, Helvetica, sans-serif;
}

.form button {
  max-width: 100px;
  outline: none;
  border: none;
  color: #ffff;
  padding: 5px;
  margin: 20px 0px 0px 0px;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(211, 164, 36);
}

@media only screen and (max-width: 550px) {
  .form {
    padding: 0px 20px 0px 20px;
  }
}
