.nav {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
}

.nav li {
  list-style: none;
  height: 60px;
}

.nav a {
  color: #fff;
  background: rgb(61, 61, 61);
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.nav a:hover {
  background-color: rgb(12, 12, 12);
}

@media only screen and (max-width: 500px) {
  .nav {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
