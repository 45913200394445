.profileImage {
  position: relative;
  max-width: 250px;
  height: 250px;
  margin: 0 auto;
}

.loaderContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profileImage input {
  display: none;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.profileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a8a8a8;
  color: #fff;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  font-size: 50px;
}

.editImageButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
  color: rgb(175, 174, 174);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
