.materialsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: 0px 0px 20px 0px;
}

.materialsInputLabel {
  display: block;
  margin: 20px 0px 20px 0px;
  border-bottom: 1px solid gray;
  height: 25px;
}

.material {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  width: 100px;
}

.material input {
  width: 100%;
  box-sizing: border-box;
  margin: 0px 0px 5px 0px;
  outline: none;
  text-align: center;
  border-radius: 5px;
  border: 1px solid gray;
}

.materialImage {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.materialImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.removeMaterialButton {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(212, 71, 71);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.removeMaterialButton svg {
  color: #fff;
}

.newMaterialButtonWrapper {
  display: flex;
  align-items: end;
  height: 90px;
}

.newMaterialButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 100px;
  background-color: rgb(189, 189, 189);
  cursor: pointer;
  border-radius: 5px;
}

.newMaterialButton svg {
  color: #fff;
  font-size: 30px;
}

.fileInput {
  display: none;
}
