.role {
  gap: 10px;
  margin: 0 auto;
  display: none;
}

.roleText {
  font-size: 14px;
  text-align: center;
}

.editButton {
  color: rgb(175, 174, 174);
  cursor: pointer;
}

.fieldContainer {
  margin: auto;
  display: none;
}

.fieldContainer label {
  display: block;
  margin: 0px 0px 5px 0px;
  font-size: 14px;
}

.error {
  color: red;
  font-size: 12px;
  margin-bottom: 5px !important;
}
.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.inputContainer input {
  outline: none;
  border: 1px solid lightgray;
}

.cancleButton {
  position: absolute;
  right: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  height: 20px;
  width: 20px;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

#roleActive {
  display: flex;
}

#inputFieldActive {
  display: block;
}
