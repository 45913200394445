.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 90%;
  margin-top: 30px !important;
  margin: 0 auto;
}

.buttonContainer a {
  text-decoration: none;
}

.orderButton {
  color: #fff;
  width: 100%;
  text-align: center;
  background-color: rgb(211, 164, 36);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.clearCartButton {
  color: #fff;
  width: 100%;
  text-align: center;
  background-color: rgb(173, 0, 0);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
