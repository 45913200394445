.message {
  text-align: center;
  margin-top: 50px;
  color: gray;
}
.card {
  display: grid;
  grid-template-columns: repeat(3, 150px);
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 40px;
  padding: 0px 20px 0px 20px;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
}

#unRead {
  background-color: #bdbdbd;
}

.nameContainer {
  display: flex;
  gap: 20px;
}

.name {
  margin: 0px;
}

.text {
  margin: 0px;
}

.date {
  text-align: end;
  margin: 0px;
}

@media only screen and (max-width: 650px) {
  .card {
    grid-template-columns: repeat(2, 1fr);
  }

  .text {
    display: none;
  }
}
