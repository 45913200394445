.form {
  position: absolute;
  width: 300px;
  background-color: rgb(61, 61, 61);
  padding: 10px;
  border-radius: 5px;
  right: 80px;
}

#homeImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  background-color: lightgrey;
  cursor: pointer;
  border-radius: 5px;
}

.imgContainer {
  width: 100%;
  height: 100%;
}

.imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.addImageButton {
  color: gray;
  font-size: 40px;
}

.fileInput {
  display: none;
}

.messageContainer {
  height: 30px;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.form label {
  display: block;
  color: #fff;
  margin: 10px 0px 5px 0px;
  font-size: 14px;
}

.error {
  color: red;
  margin: 0px 0px 5px 0px;
  font-size: 14px;
}

.form input {
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  outline: none;
}

.form textarea {
  border-radius: 5px;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  outline: none;
}

.form button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  height: 30px;
  margin-top: 20px;
  background-color: rgb(211, 164, 36);
  cursor: pointer;
}

.cancleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  margin-top: 10px;
  width: 100%;
  height: 30px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
  s .form {
    right: 0px;
  }
}

@media only screen and (max-width: 900px) {
  .form {
    transform: translateX(-50%);
    left: 50%;
  }
}
