.main {
  flex: 1;
  background: linear-gradient(black 200px, white 0%);
  padding: 50px 0px 200px 0px;
}

.loadingImage {
  background-color: lightgray;
  max-width: 500px;
  height: 390px;
  margin: 0 auto;
}

@media only screen and (max-width: 500px) {
  .main {
    padding: 50px 10px 200px 10px;
  }
}
