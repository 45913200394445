.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background-color: lightgray;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
}

.imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgContainer svg {
  font-size: 50px;
  color: #ffff;
}

.fileInput {
  display: none;
}

@media only screen and (max-width: 420px) {
  .imgContainer {
    height: 200px;
  }
}

@media only screen and (max-width: 310px) {
  .imgContainer {
    height: 150px;
  }
}
