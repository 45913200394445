.closeFormButton {
  background-color: red;
  color: #fff;
  border: none;
  padding: 6px;
  cursor: pointer;
  font-size: 13px;
  display: none;
}

@media only screen and (max-width: 500px) {
  .active {
    display: block;
  }
}
