.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-width: 840px;
  margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    max-width: 640px;
  }
}

@media only screen and (max-width: 700px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    max-width: 440px;
  }
}

@media only screen and (max-width: 500px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 10px 0px 10px;
    gap: 30px;
  }
}
