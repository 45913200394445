.newSubImageButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  cursor: pointer;
  border-radius: 5px;
  height: 70px;
}

.newSubImageButton svg {
  font-size: 30px;
  color: #ffff;
}

.newSubImageButton input {
  display: none;
}

@media only screen and (max-width: 420px) {
  .newSubImageButton {
    height: 60px;
  }
}

@media only screen and (max-width: 350px) {
  .newSubImageButton {
    height: 50px;
  }
}

@media only screen and (max-width: 310px) {
  .newSubImageButton {
    height: 40px;
  }
}
