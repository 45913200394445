.cards {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 auto;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  padding: 0px 5px 0px 0px;
}

.cardWrapper {
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
}

.attachmentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(136, 136, 136);
  color: #fff;
  width: 100%;
  height: 20px;
  cursor: pointer;
}

.attachmentButton p {
  margin: 0px;
  font-size: 14px;
}

.cardWrapper input {
  display: none;
}

.attachmentCard {
  position: relative;
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  width: 100%;
  padding: 5px;
  background-color: #dfdfdf;
  gap: 10px;
  border-top: 1px solid lightgrey;
  overflow: hidden;
  box-sizing: border-box;
}

.attachmentImg {
  width: 50px;
  height: 50px;
}

.attachmentImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-right: 30px;
}

.cardBody p {
  margin: 0px;
  font-size: 14px;
}

.fileSize {
}

.removeAttachmentButton {
  position: absolute;
  display: grid;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: gray;
  cursor: pointer;
  font-size: 14px;
}

@media only screen and (max-width: 935px) {
  .cards {
    max-height: 310px;
  }
}

@media only screen and (max-width: 540px) {
  .attachmentCard {
    grid-template-columns: 70px 1fr;
  }

  .attachmentImg {
    width: 70px;
    height: 70px;
  }

  .cardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    height: 100%;
  }
}
