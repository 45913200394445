.message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error {
  border-color: red;
  color: white;
  background: red;
}

.warning {
  color: white;
  background: orange;
}

.success {
  border-color: green;
  color: white;
  background: green;
}
