.card {
  position: relative;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 50px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 5px;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.imageContainer img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.cardBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  padding-right: 30px;
}

.card h2 {
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
  padding-left: 10px;
}

.card p {
  font-size: 14px;
  margin: 0px;
  text-align: center;
}

.amountContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.amountRed {
  color: red;
}

.priceContainer {
  display: flex;
  gap: 10px;
}

.priceContainer p {
  margin: 0px;
}

.discountPrice {
  text-decoration: line-through;
  color: red;
}

.cardSmall {
  grid-template-columns: 70px 1fr;
  grid-template-rows: 70px;
}

.cardBodySmall {
  grid-template-columns: 140px;
  gap: 10px;
  padding: 0px 0px 0px 10px;
}

.cardBodySmall h2 {
  padding-left: 0px;
}

.cardBodySmall p {
  text-align: start;
}

.amountContainerSmall {
  justify-content: start;
}

@media only screen and (max-width: 540px) {
  .card {
    grid-template-columns: 70px 1fr;
    grid-template-rows: 70px;
  }

  .cardBody {
    grid-template-columns: 140px;
    gap: 10px;
    padding: 0px 0px 0px 10px;
  }

  .card h2 {
    padding-left: 0px;
  }

  .card p {
    text-align: start;
  }

  .amountContainer {
    justify-content: start;
  }
}
