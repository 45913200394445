.form {
  max-width: 1000px;
  width: 100%;
  margin-top: 50px !important;
  margin: 0 auto;
  background-color: rgb(61, 61, 61);
  border-radius: 5px;
  padding: 30px 10px 10px 10px;
  box-sizing: border-box;
}

.form h2 {
  text-align: center;
  font-weight: lighter;
  margin: 0px 0px 40px 0px;
  color: #fff;
}

.message {
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  column-gap: 20px;
}

.form label {
  display: block;
  width: 100%;
  margin: 10px 0px 5px 0px;
  font-size: 14px;
  color: #ffff;
}

.error {
  color: red;
  font-size: 13px;
  margin: 0px 0px 5px 0px;
}

.form input {
  display: block;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  outline: none;
  border: 1px gray solid;
  border-radius: 5px;
}

.form textarea {
  width: 100%;
  height: 100px;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  box-sizing: border-box;
  resize: none;
  border-radius: 5px;
}

.form button {
  display: block;
  width: 100%;
  margin-top: 20px !important;
  margin: 0 auto;
  height: 40px;
  font-size: 16px;
  color: #fff;
  background-color: rgb(211, 164, 36);
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
}
