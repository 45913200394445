.topBarContainer {
  height: 40px;
}

.topbBar {
  width: 100%;
  z-index: 10;
}

.actionBar {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  height: 40px;
  width: 100%;
  padding: 0px 20px 0px 20px;
  color: #fff;
  background-color: rgb(12, 12, 12);
  box-sizing: border-box;
}

.positionFixed {
  position: fixed;
  top: 0px;
}

.navigationWrapper {
  position: relative;
  z-index: 5;
}

.navigationContainer {
  position: absolute;
  width: 100%;
  height: 300px;
  visibility: hidden;
  overflow: hidden;
}

.navigation {
  position: absolute;
  top: -100%;
  width: 100%;
  transition: top 0.4s ease;
}

.active {
  visibility: visible;
  top: 0;
}
