.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-width: 840px;
  margin: 0 auto;
}

.card {
  position: relative;
  max-width: 200px;
  height: 270px;
  background-color: lightgray;
  border-radius: 20px;
  overflow: hidden;
  cursor: zoom-in;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translate(2px, -2px);
}

.actionButton {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    max-width: 620px;
  }
}

@media only screen and (max-width: 700px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    max-width: 420px;
  }
}

@media only screen and (max-width: 500px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 10px 0px 10px;
    gap: 30px;
  }

  .card {
    margin: 0 auto;
    box-sizing: border-box;
  }
}
