.descriptionContainer {
  background-color: rgb(61, 61, 61);
  padding: 10px;
  border-radius: 5px;
}

.description {
  width: 100%;
  color: #fff;
}

.description:first-child {
  margin-top: 0px;
}

.description a {
  color: rgb(0, 174, 255);
  text-decoration: none;
}
