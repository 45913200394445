.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 5px 0px 5px;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  animation: tooltip-appear 0.5s forwards;
}

@keyframes tooltip-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrowBottom {
  position: absolute;
  color: black;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.arrowTop {
  position: absolute;
  color: black;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  transform: rotate(180deg);
}

.normal {
  color: #fff;
  border-color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.87);
}

.normal svg {
  color: rgba(0, 0, 0, 0.87);
}

.error {
  border-color: red;
  color: #fff;
  background: red;
}

.error svg {
  color: red;
}

.warning {
  color: white;
  background: orange;
}

.warning svg {
  color: orange;
}

.success {
  border-color: green;
  color: white;
  background: green;
}

.success svg {
  color: green;
}
