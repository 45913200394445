.increaseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  color: #fff;
  background-color: rgb(202, 202, 202);
}
