.removeButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: gray;
  cursor: pointer;
  font-size: 14px;
}
