.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 20px;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
