.form {
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  border-radius: 5px;
}

.form .imgContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: lightgrey;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.form .imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.plussIcon {
  font-size: 50px;
  color: #ffff;
}

.editButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: gray;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.imageInput {
  display: none;
}

.messageContainer {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
}

.labelInput {
  display: block;
  margin: 10px 0px 5px 0px;
}

.form textarea {
  height: 250px;
  width: 100%;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid gray;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
}

.buttonsContainer button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  background-color: rgb(211, 164, 36);
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.cancleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgb(255, 0, 0);
  height: 35px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .form .imgContainer {
    height: 250px;
  }

  .form textarea {
    height: 200px;
  }
}

@media only screen and (max-width: 400px) {
  .form .imgContainer {
    height: 200px;
  }

  .form textarea {
    height: 150px;
  }
}
