.dropDown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: rgb(61, 61, 61);
  border-radius: 20px 5px 5px 20px;
  padding-right: 10px;
  cursor: pointer;
  max-width: 151px;
}

.Username {
  margin: 0px;
}

.userButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: #fff;
  color: gray;
}

.profileImageContainer {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.profileImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu {
  position: absolute;
  width: 100%;
  bottom: -123px;
  background: rgb(61, 61, 61);
  display: none;
  box-sizing: border-box;
  border-radius: 5px;
}

.menu li {
  display: grid;
  align-items: center;
  color: #fff;
  list-style: none;
  padding: 0px 5px 0px 5px;
  height: 30px;
}

.menu li:hover {
  background-color: rgb(12, 12, 12);
}

.menu li a {
  color: #fff;
  text-decoration: none;
}

.active {
  display: block;
}

@media only screen and (max-width: 500px) {
  .Username {
    display: none;
  }

  .menu {
    width: 100px;
    left: 50%;
    transform: translate(-50%);
    z-index: 11;
  }
}
