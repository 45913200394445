.buttonContainer {
  position: relative;
  width: 100%;
}

.button {
  max-width: 130px;
  padding: 5px 10px 5px 10px;

  margin: 0 auto;
  color: #ffff;
  background: rgb(61, 61, 61);
  border-radius: 5px;
  cursor: pointer;
}

.tooltopContainer {
  position: absolute;
  top: -53px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 170px;
  width: 100%;
  margin-bottom: 5px;
  margin: 0 auto;
}
