.card {
  position: relative;
  max-width: 200px;
  background-color: #ffff;
  border-radius: 10px;
  padding: 5px 5px 10px 5px;
  overflow: hidden;
  cursor: pointer;
}

.actionButton {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  z-index: 1;
}

.card a {
  text-decoration: none;
}

.imgContainer {
  position: relative;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: rgb(189, 189, 189);
}

.imageLoader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: rgb(189, 189, 189);
  background: linear-gradient(to left, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.7) 50%, rgba(51, 51, 51, 0) 100%);
  background-size: 200% 100%;
  animation: shine 2s linear infinite;
}

.stockStatus {
  position: absolute;
  bottom: -13px;
  right: 0px;
  padding: 3px;
  font-size: 12px !important;
  color: rgb(173, 248, 173) !important;
  background-color: rgba(0, 0, 0, 0.432);
}

#outOfStock {
  color: #fa8b8b !important;
}

.priceContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.priceContainer p {
  margin-top: 0px;
}

.discountPrice {
  text-decoration: line-through;
  color: red !important;
}

@keyframes shine {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.card h2 {
  font-weight: lighter;
  font-size: 16px;
  text-align: center;
  height: 36px;
  overflow: hidden;
  color: black;
}

.card p {
  font-size: 14px;
  color: gray;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .card {
    margin: 0 auto;
    box-sizing: border-box;
  }
}
